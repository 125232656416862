import type { FC } from 'react'

import { DataProvider, getDefaultManagers } from '@data-client/react'

import { AppProvider } from '@peoplevine/sdk/components/app/Provider'
import { DeveloperProvider } from '@peoplevine/sdk/components/developer/DeveloperProvider'
import { ConfigProvider, FeatureProvider, NativeAppProvider } from '@peoplevine/sdk/providers'

import RoutesProvider from '../containers/routing'
import { DrawerDetailProvider } from '../providers/DrawerDetailProvider'

const RootProvider: FC = () => (
  <DataProvider managers={getDefaultManagers()} devButton={'bottom-right'}>
    <ConfigProvider>
      <FeatureProvider>
        <AppProvider>
          <NativeAppProvider>
            <DrawerDetailProvider>
              <RoutesProvider />
            </DrawerDetailProvider>
          </NativeAppProvider>
        </AppProvider>
      </FeatureProvider>
    </ConfigProvider>
  </DataProvider>
)

export default RootProvider
