import {
  FC,
  PropsWithChildren,
  ReactNode,
  createContext,
  forwardRef,
  lazy,
  useCallback,
  useContext,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
  useTransition,
} from 'react'

import { CenteredContent } from '@peoplevine/sdk/components'
import { FullScreenDrawer } from '@peoplevine/sdk/components/drawer'
import { DrawerCloseButton } from '@peoplevine/sdk/components/drawer/DrawerCloseButton'
import Result from '@peoplevine/sdk/components/result'

type DrawerTypes = 'order' | 'creditCard'

type DrawerFunc = {
  show: () => void
  loading: boolean
  Drawer: ReactNode
}

type CustomerDrawerFunc = {
  show: (id: Data.ID) => void
  loading: boolean
  Drawer: ReactNode
}

type DrawerFuncs = {
  [key in DrawerTypes as `${key}Drawer`]: (id: Data.ID) => DrawerFunc
} & {
  customDrawer: (component: FC<Data.Identified>) => CustomerDrawerFunc
}

const DrawerComponents: { [key in DrawerTypes]: FC<Data.Identified> } = {
  creditCard: lazy(() => import('../components/account/tabs/wallet/credit-card/CreditCardEdit')),
}

const DefaultDrawerFunc = {
  show: () => {},
  loading: false,
  Drawer: <Result.NotFound />,
}
type ContextProps = {} & DrawerFuncs

const defaultValues: ContextProps = {
  customDrawer: () => DefaultDrawerFunc,
  orderDrawer: () => DefaultDrawerFunc,
  creditCardDrawer: () => DefaultDrawerFunc,
}

const DrawerDetails = createContext<ContextProps>(defaultValues)

type DrawerLayoutRef = {
  toggle: (open: boolean) => void
  open: boolean
}

const DrawerLayout = forwardRef(({ children }: PropsWithChildren, ref: React.Ref<DrawerLayoutRef>) => {
  const [drawerOpen, setDrawerOpen] = useState(false)

  useImperativeHandle(
    ref,
    () => ({
      toggle: setDrawerOpen,
      open: drawerOpen,
    }),
    [drawerOpen, setDrawerOpen],
  )

  return (
    <FullScreenDrawer
      closeIcon={<DrawerCloseButton drawerPlacement={'fullscreen'} />}
      open={drawerOpen}
      mask={false}
      forceRender={false}
      destroyOnClose={true}
      maskClosable={false}
      onClose={() => setDrawerOpen(false)}
      styles={{
        header: {
          borderBottom: 'none',
          height: 0,
          paddingBottom: 0,
        },
        body: { padding: 16 },
      }}
    >
      <CenteredContent width={1200}>{children}</CenteredContent>
    </FullScreenDrawer>
  )
})

const DrawerDetailProvider: FC<PropsWithChildren> = ({ children }) => {
  const useDrawerComponent = (Component: FC<Data.Identified>, id: Data.ID | null) => {
    const drawerRef = useRef<DrawerLayoutRef>(null)
    const [loading, startTransition] = useTransition()

    const handleOpen = useCallback(() => {
      if (drawerRef.current?.open) return
      drawerRef && startTransition(() => drawerRef.current?.toggle(true))
    }, [drawerRef, startTransition])

    const Drawer = useMemo(
      () =>
        id ? (
          <DrawerLayout ref={drawerRef}>
            <Component id={id} />
          </DrawerLayout>
        ) : null,
      [Component, id],
    )

    return {
      show: handleOpen,
      loading,
      Drawer,
    }
  }

  const useCustomDrawer = (Component: FC<Data.Identified>) => {
    const drawerRef = useRef<DrawerLayoutRef>(null)
    const [loading, startTransition] = useTransition()
    const [id, setId] = useState<Data.ID>()
    const handleOpen = useCallback(
      (id: Data.ID) => {
        startTransition(() => {
          setId(id)
          drawerRef && drawerRef.current?.toggle(true)
        })
      },
      [drawerRef, startTransition],
    )

    const Drawer = useMemo(
      () =>
        id ? (
          <DrawerLayout ref={drawerRef}>
            <Component id={id} />
          </DrawerLayout>
        ) : null,
      [Component, id],
    )

    return {
      show: handleOpen,
      loading,
      Drawer,
    }
  }
  const useDrawer = (type: DrawerTypes) => (id: Data.ID) => useDrawerComponent(DrawerComponents[type], id)

  return (
    <DrawerDetails.Provider
      value={{
        customDrawer: useCustomDrawer,
        orderDrawer: useDrawer('order'),
        creditCardDrawer: useDrawer('creditCard'),
      }}
    >
      {children}
    </DrawerDetails.Provider>
  )
}

const useDrawerDetails: () => ContextProps = () => useContext(DrawerDetails)

export { DrawerDetailProvider, useDrawerDetails }
