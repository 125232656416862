import { FC, PropsWithChildren, createContext, useCallback, useContext } from 'react'

type AnalyticsContextProps = {
  trackEvent: (eventName: string, data: Record<string, any>) => void
}

const defaultValues: AnalyticsContextProps = {
  trackEvent: () => {},
}

const AnalyticsContext = createContext<AnalyticsContextProps>(defaultValues)

const AnalyticsProvider: FC<PropsWithChildren> = ({ children }) => {
  const trackEvent = useCallback(
    async (eventName?: string, data?: Record<string, any>) => {
      if (!window.umami) return
      window.umami?.track(eventName, data)
    },
    [window.umami],
  )

  return (
    <AnalyticsContext.Provider
      value={{
        trackEvent,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  )
}

const useAnalytics: () => AnalyticsContextProps = () => useContext(AnalyticsContext)

export { AnalyticsProvider, useAnalytics }
