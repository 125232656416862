import { FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import { Link, Outlet } from 'react-router-dom'
import { useTimeout } from 'react-use'

import { Col, ColProps, Flex, Row } from 'antd'

import { useColor } from '@peoplevine/sdk/components/app'
import { Logo, useLoginLogo, useLogoType } from '@peoplevine/sdk/components/logo'
import { LogoImage } from '@peoplevine/sdk/components/logo/Logo'
import { useResponsive } from '@peoplevine/sdk/components/screen/Breakpoint'
import { Title } from '@peoplevine/sdk/components/text/Title'
import { SloganProps } from '@peoplevine/sdk/datasource/config'
import { useConfig } from '@peoplevine/sdk/providers/ConfigProvider'

const LogoView: FC = () => {
  const { slogans } = useConfig().props
  const { components, color } = useColor()
  const layoutBgColor = color(components?.Layout?.colorBgLayout)
  const hasImages = slogans?.some((slogan) => slogan?.image)
  const logoType = useLogoType()
  const loginLogo = useLoginLogo()

  const justify = loginLogo?.justify ?? logoType?.logo?.justify ?? 'center'

  return (
    <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: justify }}>
      {loginLogo ? (
        loginLogo.src ? (
          <LogoImage src={loginLogo.src} style={{ maxWidth: loginLogo?.width }} />
        ) : (
          <Logo dark={hasImages ? false : layoutBgColor.isDark()} type={logoType?.logo?.expanded ?? 'icon+text'} />
        )
      ) : (
        <Link to={'/'} style={{ overflow: 'hidden', display: 'flex', justifyContent: logoType.justify }}>
          <Logo dark={hasImages ? false : layoutBgColor.isDark()} type={logoType?.logo?.expanded ?? 'icon+text'} />
        </Link>
      )}
    </div>
  )
}

const useCurrentSlide = () => {
  const { slogans } = useConfig().props
  const { isMobile } = useResponsive()

  const resolved = useMemo(() => {
    return slogans.reduce(
      (acc, it) => {
        if (it.mobile || it.mobileOnly) {
          acc.mobile.push(it)
        }
        if (!it.mobileOnly) {
          acc.desktop.push(it)
        }
        return acc
      },
      { mobile: [], desktop: [] } as { mobile: SloganProps[]; desktop: SloganProps[] },
    )
  }, [slogans])

  const getRandomSlogan = useCallback(() => {
    const randomIndex = Math.floor(Math.random() * slogans?.length)
    const mobileSlogans = resolved?.mobile.length ? resolved?.mobile : resolved.desktop
    return isMobile && mobileSlogans ? mobileSlogans?.[randomIndex] : resolved.desktop?.[randomIndex]
  }, [isMobile, slogans])

  const [currentSlide, setCurrentSlide] = useState(getRandomSlogan())
  const [showNextSlide, reset] = useTimeout(30000)
  const showNext = showNextSlide()

  useEffect(() => {
    if (showNext === true) {
      setCurrentSlide(getRandomSlogan())
    }
    return reset
  }, [showNext, getRandomSlogan])

  return currentSlide
}

type SlidesColProps = {
  text?: boolean
} & ColProps
const SlidesCol: FC<SlidesColProps> = ({ text = true, ...colProps }) => {
  const { isMobile } = useResponsive()
  const currentSlide = useCurrentSlide()

  const styles = useMemo(() => {
    const style: React.CSSProperties = {
      height: '100%',
      backgroundImage: currentSlide?.image && `url(${currentSlide?.image})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      ...(isMobile
        ? {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            maxWidth: 'unset',
          }
        : {}),
    }
    return style
  }, [isMobile, currentSlide])

  return (
    <Col style={styles} {...colProps}>
      <Row
        style={{
          alignContent: 'space-between',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: '50%',
          textShadow: '1px 1px 5px #212224',
          transform: 'translate(0, -50%)',
          padding: 40,
        }}
        gutter={[0, 40]}
        justify={'center'}
        align={'stretch'}
      >
        {!isMobile && currentSlide && (
          <>
            <Col span={24}>
              <LogoView />
            </Col>
            {text && (
              <>
                <Col xs={20} md={18} lg={20} xl={16}>
                  <Title translate={'no'} type={'light'} level={1}>
                    {currentSlide.title}
                  </Title>
                </Col>
                <Col offset={2} span={24}>
                  <Title translate={'no'} type={'light'} level={5}>
                    {currentSlide.subtitle}
                  </Title>
                </Col>
              </>
            )}
          </>
        )}
      </Row>
    </Col>
  )
}

type PublicViewProps = {
  fullscreen?: boolean
} & PropsWithChildren

const PublicBannerView: FC<PublicViewProps> = ({ children = <Outlet />, fullscreen = false }) => {
  const { isMobile } = useResponsive()
  const currentSlide = useCurrentSlide()
  const colProps = useMemo(() => (fullscreen ? { span: 24 } : { span: 0, md: 12 }), [fullscreen])

  return (
    <Row
      align={'top'}
      style={{
        height: '100vh',
        width: '100%',
        ...(isMobile
          ? {
              backgroundImage: currentSlide?.image && `url(${currentSlide?.image})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }
          : {}),
      }}
    >
      <SlidesCol {...colProps} text={!fullscreen} />
      <Col span={24} md={0} style={{ height: 'fit-content', padding: '24px' }}>
        <LogoView />
      </Col>
      {children}
    </Row>
  )
}

const PublicView: FC<PropsWithChildren> = ({ children = <Outlet /> }) => {
  const { isMobile } = useResponsive()
  const { token } = useColor()

  return (
    <PublicBannerView>
      <Col
        span={24}
        md={12}
        style={
          isMobile
            ? {
                backgroundColor: 'rgba(255,255,255, 0.97)',
                padding: '32px 0',
              }
            : {
                overflowY: 'scroll',
                padding: '32px 0',
                height: '100%',
                minHeight: 'calc(100%-32px)',
              }
        }
      >
        <Flex
          style={{
            height: 'calc(100%+32px)',
            minHeight: '100%',
            width: '100%',
          }}
          vertical
          align={'center'}
          justify={'center'}
        >
          {children}
        </Flex>
      </Col>
    </PublicBannerView>
  )
}

export { PublicBannerView }
export default PublicView
