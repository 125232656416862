import { useCallback } from 'react'
import { PrimitiveType } from 'react-intl'

import { App, NotificationArgsProps } from 'antd'

import { ApiError } from '../api'
import { useColor } from '../components/app'
import { Button } from '../components/button/Button'
import IvyIcon from '../components/icon'
import Text from '../components/text/Text'
import './Notification.scss'

type NotificationProps = {
  context?: Record<string, PrimitiveType>
} & NotificationArgsProps

type ContextProps = {
  notify: (props: NotificationProps) => void
  notifyOnError: (error: Partial<Pick<Error, 'name' | 'message'>>) => void
}

const iconMap: { [key in NonNullable<NotificationProps['type']>]: Design.SymbolIcon } = {
  success: 'check-circle',
  error: 'close-circle',
  warning: 'warning',
  info: 'info-circle',
}
const useNotification: () => ContextProps = () => {
  const { notification } = App.useApp()
  const { bgByVariant, token } = useColor()
  const notify = useCallback(
    ({ type = 'success', message, description, closable = true, context, ...props }: NotificationProps) => {
      message &&
        notification.open({
          type,
          closable,
          btn: closable && (
            <Button
              type={'dashed'}
              onClick={() => notification.destroy()}
              style={{
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                width: 'fit-content',
                height: 'fit-content',
                padding: 0,
                position: 'absolute',
                top: 15,
                right: 20,
              }}
              icon={'symbol/close'}
            />
          ),
          icon: (
            <IvyIcon
              type={`symbol/${iconMap[type] || 'info-circle'}`}
              style={{ display: 'flex', marginTop: -2 }}
              size={26}
            />
          ),
          closeIcon: false,
          style: {
            padding: '15px 33px 15px 20px',
            backgroundColor: bgByVariant(type, true),
            borderColor: bgByVariant(type),
            borderRadius: token.borderRadius,
            boxShadow:
              '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, .08), 0 9px 28px 8px rgba(0, 0, 0, .05)',
          },
          message:
            typeof message === 'string' ? (
              <Text strong font={{ family: 'primary', size: 14, weight: 400 }} context={context}>
                {message}
              </Text>
            ) : (
              message
            ),
          description:
            typeof description === 'string' ? (
              <Text font={{ family: 'primary', size: 14, weight: 400 }} context={context}>
                {description}
              </Text>
            ) : (
              description
            ),
          ...props,
        })
    },
    [bgByVariant, notification, token.borderRadius],
  )

  const notifyOnError = useCallback(
    (error: Partial<ApiError>) => {
      notify({
        type: 'error',
        message: error?.name,
        description: <Text>{error?.message}</Text>,
      })
    },
    [notify],
  )

  return { notify, notifyOnError }
}

export { useNotification }
