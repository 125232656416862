import { FC } from 'react'

import { Drawer, DrawerProps } from 'antd'

import './index.scss'

interface FullScreenDrawerProps extends DrawerProps {
  hideCloseIcon?: boolean
}

const FullScreenDrawer: FC<FullScreenDrawerProps> = ({ hideCloseIcon, ...baseProps }) => {
  return (
    <Drawer
      placement={'top'}
      height={'100%'}
      width={'100%'}
      closable={!hideCloseIcon}
      {...baseProps}
    />
  )
}

export { FullScreenDrawer }
