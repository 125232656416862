import ls from 'localstorage-slim'
import { StorageConfig } from 'localstorage-slim/dist/types'

export default function useLocalStorage() {
  function parseJSON<T>(value: string | null): T | undefined {
    try {
      return value === 'undefined' ? undefined : JSON.parse(value ?? '')
    } catch (err) {
      console.log('parsing error on', { value }, err)
      return undefined
    }
  }

  function getOrSet<T>(key: string, value: T | (() => T), options: StorageConfig = {}) {
    try {
      return getValue<T>(key)
    } catch (error) {
      return setValue(key, value instanceof Function ? value() : value, options)
    }
  }

  function setValue<T>(key: string, value: T, options: StorageConfig = {}) {
    try {
      ls.set(key, value, options)
      return value
    } catch (error) {
      return null
    }
  }

  function getValue<T>(key: string) {
    try {
      const item = ls.get<string>(key)
      return item as T
    } catch (error) {
      return null
    }
  }

  function removeValue(key: string) {
    ls.remove(key)
  }

  return {
    getOrSet,
    setValue,
    getValue,
    removeValue,
  }
}
