import { type FC, lazy, useEffect, useMemo } from 'react'
import { Outlet, RouteObject, RouterProvider, createBrowserRouter, useLocation } from 'react-router-dom'

import Result from '@peoplevine/sdk/components/result'
import { PageConfig } from '@peoplevine/sdk/datasource/config'
import { AuthProvider, useMenuConfig } from '@peoplevine/sdk/providers'
import { PaymentProvider } from '@peoplevine/sdk/providers/payment/Payment'

import {
  errorBoundary,
  guestPolicy,
  membersPolicy,
  menuPagePolicy,
  pathAliases,
  plusPlanPolicy,
  routeEntries,
  routeObject,
} from './decorators'
import { drawerRoutes, globalNetworkRoutes, guestRoutes, memberRoutes, sharedRoutes } from './routes'

const Empty = lazy(() => import('src/layouts/Empty'))
const Shared = lazy(() => import('src/layouts/Shared'))
const Unauthorized = lazy(() => import('src/layouts/Unauthorized'))
const Authorized = lazy(() => import('src/layouts/Authorized'))
const Drawer = lazy(() => import('src/layouts/Drawer'))
const DefaultLayout = lazy(() => import('src/layouts/Default'))
const DynamicPage = lazy(() => import('src/components/dynamic'))

function createDynamicRoutes(pages?: PageConfig[]): RouteObject | null {
  const dynamic = pages
    ?.filter((p) => p.type === 'dynamic')
    ?.reduce((acc, page) => {
      acc = acc ?? []
      acc[page.uri] = {
        Component: DynamicPage,
        membersOnly: page.membersOnly,
        path: page.uri,
      }
      acc[`${page.uri}/:id`] = {
        Component: DynamicPage,
        membersOnly: page.membersOnly,
        path: `${page.uri}/:id`,
      }
      return acc
    }, {})

  return dynamic
    ? {
        Component: DefaultLayout,
        id: 'default-routes-layout',
        children: routeEntries(dynamic)
          .flatMap(pathAliases)
          .map(membersPolicy)
          .map(menuPagePolicy)
          .map(guestPolicy)
          .map(errorBoundary)
          .map(routeObject),
      }
    : null
}

function createRoutesFromConfig(): RouteObject[] {
  return [
    {
      // Component: () => <PublicBannerView fullscreen />,
      Component: Empty,
      id: 'global-network-routes',
      children: routeEntries(globalNetworkRoutes).map(routeObject),
    },
    {
      Component: Shared,
      id: 'shared-routes',
      secure: false,
      children: routeEntries(sharedRoutes).flatMap(pathAliases).map(routeObject),
    },
    {
      Component: Unauthorized,
      id: 'unauthorized-routes',
      secure: false,
      children: routeEntries(guestRoutes).flatMap(pathAliases).map(routeObject),
    },
    {
      Component: Authorized,
      id: 'authorized-routes',
      secure: true,
      children: [
        {
          Component: DefaultLayout,
          id: 'default-routes',
          children: routeEntries(memberRoutes)
            .map((it) => ({ ...it, secured: true }))
            .flatMap(pathAliases)
            .map(plusPlanPolicy)
            .map(membersPolicy)
            .map(menuPagePolicy)
            .map(guestPolicy)
            .map(errorBoundary)
            .map(routeObject),
        },
        {
          Component: Drawer,
          membersOnly: false,
          id: 'drawer-layout',
          children: routeEntries(drawerRoutes)
            .map((it) => ({ ...it, membersOnly: true, secured: true }))
            .flatMap(pathAliases)
            .map(guestPolicy)
            .map(errorBoundary)
            .map(routeObject),
        },
      ],
    },
    {
      Component: Authorized,
      id: 'dynamic-routes',
      secure: true,
      children: [],
    },
    {
      path: '*',
      id: 'fallback-routes',
      Component: Result.NotFound,
    },
  ].map(guestPolicy)
}

/**
 * Restores scroll position to the top of the page on route change.
 * @constructor
 */
function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' })
  }, [pathname])

  return null
}

const RootRoute: FC = () => {
  return (
    <AuthProvider>
      <PaymentProvider>
        <ScrollToTop />
        <Outlet />
      </PaymentProvider>
    </AuthProvider>
  )
}

const defaultRoutes = { Component: RootRoute, children: createRoutesFromConfig() }
/**
 * Provides the routing configuration to the application.
 * dynamic routes are loaded from the menu configuration here to allow the pages to be pulled from configuration
 */
const RoutesProvider = () => {
  const { pages } = useMenuConfig()

  const routes = useMemo(() => {
    const dynamic = createDynamicRoutes(pages)
    const dynamicRoutes = defaultRoutes.children?.find((it) => it.id === 'dynamic-routes')
    if (dynamic && dynamicRoutes) {
      dynamicRoutes.children = [dynamic]
    }
    return defaultRoutes
  }, [])

  const router = createBrowserRouter([{ Component: RootRoute, children: [routes] }])

  return <RouterProvider router={router} />
}

export default RoutesProvider
