import { FC, ReactNode, useRef, useState } from 'react'

import { CarouselProps, Carousel as Wrapped } from 'antd'
import { createStyles } from 'antd-style'
import { CarouselRef } from 'antd/lib/carousel'

import { withPrefix } from '../../providers/ConfigProvider'
import { useColor } from '../app'
import { Button } from '../form'
import { Image } from '../image/Image'
import { Breakpoint, useResponsive } from '../screen/Breakpoint'

const useStyles = createStyles(({ token, css }: { token: any; css: any }) => ({
  carouselDots: css`
    .slick-dots {
      bottom: -20px;

      li {
        button {
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background-color: ${token.colorTextBase};
        }

        &.slick-active button {
          width: 20px;
          background-color: ${token.colorTextBase};
        }
      }
    }
  `,

  buttonHoverFix: css`
    .pv-btn-variant-text:not(:disabled):not(.pv-btn-disabled):hover {
      background: unset;
    }
  `,
}))

const Carousel: FC<CarouselProps> = (props) => (
  <Wrapped
    className={withPrefix(props.className ? props.className : '', props.dots ? 'carousel-with-dots' : '')}
    dots={props.dots ?? false}
    infinite={false}
    draggable
    speed={500}
    slidesToShow={props.slidesToShow ?? 3}
    slidesToScroll={props.slidesToScroll ?? 3}
    responsive={
      props.responsive ?? [
        {
          breakpoint: Breakpoint.LG,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: Breakpoint.SM,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ]
    }
    {...props}
  />
)

export type CarouselBannerProps = {
  images?: string[] | ReactNode[]
  fluid?: boolean
  height?: number
  navigation?: boolean
  dots?: boolean
  placeholder?: Design.PlaceholderIcon
} & CarouselProps

const CarouselBanner: FC<CarouselBannerProps> = ({
  images = [],
  height,
  fluid = false,
  navigation = true,
  dots = true,
  placeholder,
  ...props
}) => {
  const { components } = useColor()
  const { styles } = useStyles()
  const { isMobile } = useResponsive()
  const slider = useRef<CarouselRef>(null)
  const [prevEnabled, setPrevEnabled] = useState(true)
  const [nextEnabled, setNextEnabled] = useState(true)

  const beforeChange = (from: number, to: number) => {
    setPrevEnabled(to > 0)
    setNextEnabled(to < images.length - 1)
  }
  return (
    <div
      className={styles.carouselDots}
      style={{
        margin: fluid && isMobile ? `0 -${components.Layout.bodyPaddingHorizontalSM}px` : 'initial',
      }}
    >
      <Wrapped
        ref={slider}
        arrows={false}
        dots={dots && images.length > 1}
        infinite={false}
        autoplay
        draggable
        slidesPerRow={1}
        speed={500}
        adaptiveHeight
        beforeChange={beforeChange}
        {...props}
      >
        {images.map((item, index) =>
          typeof item === 'string' ? (
            <Image
              style={{ objectFit: 'cover' }}
              height={height}
              key={index}
              src={item}
              width={'100%'}
              placeholder={placeholder}
            />
          ) : (
            item
          ),
        )}
      </Wrapped>
      {navigation && images && images?.length > 1 && (
        <div
          className={`${styles.buttonHoverFix} ${withPrefix('carousel-banner-nav')}`}
          style={{ display: 'flex', justifyContent: 'end', marginRight: -16 }}
        >
          <Button
            ghost
            key={'prev'}
            disabled={!prevEnabled}
            className={withPrefix('carousel-banner-nav-arrows-prev')}
            type={'text'}
            icon={'directional/arrow-left'}
            onClick={() => slider.current?.prev()}
          />
          <Button
            ghost
            key={'next'}
            disabled={!nextEnabled}
            className={withPrefix('carousel-banner-nav-arrows-next')}
            type={'text'}
            icon={'directional/arrow-right'}
            onClick={() => slider.current?.next()}
          />
        </div>
      )}
    </div>
  )
}

export { Carousel, CarouselBanner }
