import { FunctionComponent, PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'
import { isAndroid, isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'

import { Flex, Modal, Typography } from 'antd'

import { useApp, useColor } from 'src/components/app'
import { PortalSettings } from 'src/datasource/config'
import useLocalStorage from 'src/hooks/useLocalStorage'

import { Button } from '../components/button/Button'
import { FullScreenDrawer } from '../components/drawer'
import { Logo } from '../components/logo'
import { Text, Title } from '../components/text'
import { I18nProvider, useConfig } from '../providers'

type AppMessage =
  | 'OPEN_MENU'
  | 'CLOSE_MENU'
  | 'CONTACTS'
  | 'DIGITAL_ID'
  | 'OPEN_PDF'
  | 'USERNAME'
  | 'LOGIN'
  | 'FACE_ID'
  | 'CHANGE_NETWORK'
  | 'HOME_HOUSE'
  | 'LOG'
  | 'FETCH_HOUSE'
  | 'TOKEN'
  | 'LOGOUT'
  | 'V2'

type EventCallback = {
  event: AppMessage
  callback: (data: any) => void
}

type Dispatch = {
  type: AppMessage
  body?: any
}

type NativeAppContext = {
  isNativeApp: boolean
  detectDevice: { isMobile: boolean }
  subscribe: (evt: EventCallback) => EventCallback
  unsubscribe: (evt: EventCallback) => void
  dispatch: (data: Dispatch) => void
  log: (evt: string, message?: any) => void
}

const defaultValue: NativeAppContext = {
  isNativeApp: false,
  detectDevice: { isMobile: false },
  subscribe: () => ({
    event: 'OPEN_MENU',
    callback: () => {},
  }),
  unsubscribe: () => {},
  dispatch: () => {},
  log: () => {},
}

const NativeApp = createContext<NativeAppContext>(defaultValue)

const subscriptions: EventCallback[] = []

const dispatch = (data: any) => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(JSON.stringify(data))
  }
}

const isNativeApp = Boolean(!!(window as any).ReactNativeWebView)

function NativeAppProvider({ children }: PropsWithChildren) {
  const { setValue, getValue } = useLocalStorage()

  const {
    settings: { app },
  } = useConfig()
  const [isV2, setIsV2] = useState<boolean | undefined>(undefined)
  const [queue, setQueue] = useState<Dispatch[]>([])

  useEffect(() => {
    const tm = setTimeout(() => {
      if (isV2 === undefined && !getValue('v2')) {
        setIsV2(false)
      }
    }, 2000)

    const v2 = subscribe({
      event: 'V2',
      callback: () => {
        setIsV2(true)
        setValue('v2', true)
      },
    })

    return () => {
      clearTimeout(tm)
      unsubscribe(v2)
    }
  }, [])

  const log = (evt: string, message?: any) => {
    dispatch({
      type: 'LOG',
      body: {
        event: evt,
        message: message,
      },
    })
  }
  const subscribe = (cb: EventCallback) => {
    subscriptions.push(cb)

    if (queue.length > 0) {
      queue.forEach((msg) => {
        // Ensure the event matches before invoking the callback
        if (cb.event === msg.type) {
          cb.callback(msg.body)
        }
      })
      setQueue([])
    }

    return cb
  }

  const unsubscribe = (cb: EventCallback) => {
    const index = subscriptions.indexOf(cb)
    if (index > -1) {
      subscriptions.splice(index, 1)
    }
  }

  useEffect(() => {
    if (window.ReactNativeWebView) {
      document.body.classList.add('is-mobile-app')
      window.ReactNativeWebView.onDispatch = (msg: AppMessage, data: any) => {
        const matchingSubscriptions = subscriptions.filter((cb) => cb.event === msg)

        if (matchingSubscriptions.length > 0) {
          matchingSubscriptions.forEach((cb) => {
            cb.callback(JSON.parse(data))
          })
        } else {
          // enqueue messages that don't match any subscriptions
          setQueue((prev) => [...prev, { type: msg, body: JSON.parse(data) }])
        }
      }
    }

    isNativeApp && window.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'done' }))
  }, [])

  return (
    <NativeApp.Provider
      value={{
        detectDevice: { isMobile },
        isNativeApp,
        subscribe,
        unsubscribe,
        dispatch,
        log,
      }}
    >
      {isNativeApp && isV2 === false ? (
        <UpgradeBanner open={/* isNativeApp && isV2 === false */ true} app={app} />
      ) : (
        children
      )}
    </NativeApp.Provider>
  )
}

const UpgradeBanner: FunctionComponent<{
  app: PortalSettings['app']
  open: boolean
}> = ({ app }) => {
  const link = isAndroid
    ? `https://play.google.com/store/apps/details?id=${app.package}`
    : `https://apps.apple.com/us/app/id${app.appleAppId}`
  const {  settings } = useConfig()
  return (
    <FullScreenDrawer
      closeIcon={false}
      open={true}
      closable={false}
      styles={{
        wrapper: {
          transform: 'none',
        },
      }}
    >
      <Flex
        vertical
        gap={64}
        style={{ height: 'calc(100vh - 80px)', width: '100%' }}
        align={'center'}
        justify={'center'}
      >
        <Flex align={'center'} vertical gap={16}>
          <Title level={1}>App update required</Title>
          <Text>
            {`You are using an older version of the ${settings.app.appName} app. Please update to the latest version for the best experience.`}
          </Text>
          <Button type={'primary'} href={link} target={'_blank'}>
            Update
          </Button>
        </Flex>
      </Flex>
    </FullScreenDrawer>
  )
}

const useNativeApp: () => NativeAppContext = () => useContext(NativeApp)

export { NativeAppProvider, useNativeApp }
