const routes = {
  _wildcard: '*',
  accountBillingTab: '/account/billing/:tab?',
  accountContact: '/account/contact',
  accountDue: '/account/billing/dues/:id',
  accountEvent: '/account/event/:id/:action?',
  accountInvoices: '/account/billing/invoices',
  accountMembershipSubmember: '/account/memberships/:id/submembers',
  accountMemberships: '/account/memberships/:id?',
  accountMembership: '/account/memberships',
  accountMembershipsNomination: '/account/memberships/nominate',
  accountOrder: '/account/billing/orders/:id',
  accountOrders: '/account/billing/orders',
  accountPerksTab: '/account/perks',
  accountProfileTab: '/account/profile/:tab?',
  accountStatement: '/account/billing/statements/:id',
  accountSection: '/account/:section',
  accountTransaction: '/account/billing/transactions/:id',
  accountWalletBank: '/account/wallet/payment-methods/bank-accounts/:id',
  accountWalletCreate: '/account/wallet/payment-methods/create',
  accountWalletCreditCard: '/account/wallet/payment-methods/credit-cards/:id',
  accountWalletTab: '/account/wallet/:tab?',
  accountWalletTicket: '/account/wallet/tickets/:id',
  account: '/account',
  actions: '/actions/:action?',
  activate: '/activate/:code?',
  activities: '/activities',
  activity: '/activities/:id',
  appointment: '/appointments/:id',
  appointments: '/appointments',
  blogs: '/blogs',
  blog: '/blogs/:id',
  blogDetails: '/blogs/:id',
  calendarRedirect: '/redirect/calendar/:type',
  checkout: '/checkout',
  clubBenefits: '/club-benefits/:id',
  clubBenefitsTab: '/club-benefits',
  vouchersTab: '/club-benefits/vouchers',
  cookie: '/cookie',
  dining: '/dining/:id/:tab?',
  diningCategory: '/dining/categories',
  dinings: '/dining',
  directory: '/directory',
  directoryCompany: '/directory/companies/:id',
  directoryMember: '/directory/members/:id',
  directoryPage: '/directory/:id',
  event: '/events/:id',
  events: '/events',
  faq: '/faq/:arg?',
  forgotPassword: '/forgot-password',
  forgotPasswordReset: '/password-reset/:id',
  generalError: '/error/:response?',
  globalNetworkSwitch: '/global-network',
  guestList: '/guest-pass',
  guestPassShare: '/guest-pass/:serial',
  guestTicket: '/guest-tickets/:serial',
  guestVoucher: '/guest-vouchers/:id/:pin',
  index: '/',
  message: '/messages/:id?',
  messages: '/messages',
  membershipPaywall: '/membership-payment',
  membershipPaywallId: '/membership-payment/:id',
  myClub: '/my-club',
  mySchedule: '/my-schedule',
  myScheduleAppointments: '/my-schedule/appointments/:id',
  myScheduleBooking: '/my-schedule/:type/:id',
  myScheduleEvent: '/my-schedule/events/:id',
  myScheduleReservations: '/my-schedule/reservations/:id',
  notifications: '/notifications',
  notificationsTab: '/notifications/:tab',
  pageId: '/pages/:id',
  passbookDownload: '/passbook/:id',
  privacy: '/privacy/:arg?',
  register: '/register',
  reservation: '/reservations/:id',
  reservations: '/reservations',
  room: '/rooms/:id',
  rooms: '/rooms',
  shop: '/product',
  shopCategory: '/product/categories',
  shopMenu: '/product/menu',
  spa: '/spa-wellness/:id',
  spas: '/spa-wellness',
  static: '/(terms|privacy|cookie)',
  survey: '/survey/:id',
  form: '/form/:id',
  terms: '/terms/:arg?',
  userLogin: '/login',
  userLogout: '/logout',
  verifyEmail: '/verify-email/:code',
  verifySms: '/verify-sms',
  vouchers: '/vouchers',
  voucher: '/account/wallet/vouchers/:id',
} as const

export { routes }
export default routes
