import { createContext, useCallback, useContext } from 'react'

import { useConfig } from './ConfigProvider'
import { I18nProvider } from './I18n'

type FeatureProps = {
  hasNomination?: boolean
  hasPersonalDetails?: boolean
  hasContact?: boolean
  hasGuestPass?: boolean
  hasRegistration?: boolean
  hasMemberDirectory: boolean
  hasGuestDirectory?: boolean
  hasSms?: boolean
  hasMessaging: boolean
  hasStripeProcessor: boolean
  editPrimaryInfo?: boolean
  globalNetwork?: boolean
}
const defaultFeatures: FeatureProps = {
  hasNomination: false,
  hasPersonalDetails: false,
  hasContact: false,
  hasGuestPass: false,
  hasMemberDirectory: false,
  hasGuestDirectory: false,
  hasSms: false,
  hasMessaging: false,
  hasStripeProcessor: false,
  hasRegistration: false,
  editPrimaryInfo: false,
  globalNetwork: false,
}

const Feature = createContext(defaultFeatures)

const useFeatureToggles: () => typeof defaultFeatures = () => useContext(Feature)

const FeatureProvider = ({ children }) => {
  const { features, settings, processorId, stripeProcessor, processors, globalNetwork } = useConfig()

  const hasStripeProcessor = useCallback(() => {
    if (processorId) {
      const processor = processors?.find((p) => p.id === processorId)
      return processor ? processor.name === 'stripe' || processor.name === 'stripev2' : false
    }
    return stripeProcessor !== undefined
  }, [processorId, processors, stripeProcessor])
  return (
    <Feature.Provider
      value={{
        hasNomination: features?.surveys?.nomination,
        hasPersonalDetails: features?.surveys?.personalDetails,
        hasContact: features?.surveys?.contact,
        hasGuestPass: features?.surveys?.guestPass,
        hasGuestDirectory: settings?.guestDirectory,
        hasRegistration: features?.surveys?.registration,
        hasStripeProcessor: hasStripeProcessor(),
        hasMemberDirectory: false,
        hasMessaging: false,
        hasSms: features?.sms,
        editPrimaryInfo: features?.editPrimaryInfo,
        globalNetwork: globalNetwork?.enabled,
      }}
    >
      <I18nProvider>{children}</I18nProvider>
    </Feature.Provider>
  )
}

export { FeatureProvider, useFeatureToggles }
