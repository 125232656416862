import { type FC, type PropsWithChildren, useMemo } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import { resolveCssVarReferences } from '../components/app'
import type { ColorKeys } from '../themes/default'
import { useConfig } from './ConfigProvider'
import { useI18n } from './I18n'
import { useNativeApp } from './NativeApp'

function applyCssVariables(vars: Partial<CamelCasedProps<{ [key in ColorKeys]: string }>>) {
  const resolved = resolveCssVarReferences(vars)
  const cssVars = Object.entries(resolved)
    .map(([key, value]) => `--pv-${key}: ${value};`)
    .join('\n')

  return `:root { ${cssVars} }`
}

const MetaProvider: FC<PropsWithChildren> = ({ children }) => {
  const { isNativeApp } = useNativeApp()
  const {
    features,
    props: { favicon },
    theme,
    company,
    analytics,
  } = useConfig()
  const { locale } = useI18n()
  const platform = useMemo(() => (isNativeApp ? 'mobileApp' : 'web'), [isNativeApp])

  return (
    <HelmetProvider>
      <Helmet htmlAttributes={{ lang: locale.language }}>
        <title>{`${company.name} Member Portal`}</title>
        <link href={favicon ?? '/favicon.svg'} rel={'icon'} type={'image/svg'} />
        <style type={'text/css'} id={'theme-variables'}>
          {applyCssVariables(theme)}
        </style>
        {features?.appId && (
          <meta
            name={'apple-itunes-app'}
            content={`app-id=${features.appId}, app-argument=pvapp:///redirect?to=${window.location.pathname}`}
          />
        )}
        {analytics?.umami && (
          <script
            defer
            src='https://analytics.internal.peoplevine.dev/script.js'
            data-website-id={analytics.umami}
            data-tag={platform}
          />
        )}
      </Helmet>
      {children}
    </HelmetProvider>
  )
}

export { MetaProvider }
