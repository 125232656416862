import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useCache, useController, useLoading } from '@data-client/react'

import { AccountResource } from '../api/account'
import { OverlayLoader } from '../components/loader'
import { AccountEntity, type AccountFlag, TokenEntity } from '../datasource/account/account'
import routes from '../routes'
import { fetchRefresh, fetchToken, removeToken, sessionId, setToken } from '../services/tokens'
import { useConfig } from './ConfigProvider'
import { useNativeApp } from './NativeApp'
import { useNotification } from './Notification'

type ContextProps = {
  authenticate: (credentials: API.LoginRequest<'customer_password'>) => Promise<boolean>
  ssoLogin: (token: string) => Promise<boolean>
  authenticated: boolean | undefined
  logout: () => void
  user?: AccountEntity | null
  flag: AccountFlag
  sessionId: string
}

const defaultValues: ContextProps = {
  authenticate: async () => false,
  ssoLogin: async () => false,
  authenticated: undefined,
  logout: async () => undefined,
  flag: '',
  sessionId: '',
}

const Auth = createContext<ContextProps>(defaultValues)

function AuthProvider({ children }: PropsWithChildren) {
  const { invalidateAll, fetch } = useController()
  const { company } = useConfig()
  const { notifyOnError } = useNotification()
  const navigate = useNavigate()
  const cached = useCache(AccountResource.me)
  const [user, setUser] = useState<AccountEntity | undefined>(cached)
  const { subscribe, unsubscribe } = useNativeApp()
  const [authenticated, setAuthenticated] = useState<boolean>()

  const fetchAccount = useCallback(async () => {
    return await fetch(AccountResource.me)
      .then((customer) => {
        setUser(customer)
        setAuthenticated(true)
        return true
      })
      .catch(() => {
        setAuthenticated(false)
        return false
      })
  }, [fetch])

  const clearUser = useCallback(async () => {
    removeToken()
    setAuthenticated(false)
    setUser(undefined)
    window.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'LOGOUT' }))
    await invalidateAll({ testKey: (key) => !key.startsWith('/config') })
  }, [invalidateAll])

  const init = useCallback(async () => {
    await fetchToken().then((token) => {
      if (token !== null) {
        fetchAccount().then((success) => {
          setAuthenticated(success)
          // When logged in via global network - there is a chance the user might get stuck on a company portal they do not have access to
          // This will log them out to prevent an infinite loop
          if (!success) clearUser()
        })
      } else {
        setAuthenticated(false)
      }
    })
  }, [clearUser, fetchAccount])

  const [logout, loggingOut] = useLoading(async () => {
    const refreshToken = fetchRefresh()
    if (!refreshToken) return
    return await fetch(AccountResource.logout, {
      refreshToken: refreshToken,
    })
      .then(() => clearUser())
      .then(() => navigate(routes.userLogin))
  }, [fetch, invalidateAll, removeToken, fetchRefresh, navigate])

  const handleAuth = useCallback(
    async (token: TokenEntity) => {
      setToken(token)
      window.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'customerNumber', data: token.customer.id }))
      return await fetchAccount()
    },
    [setAuthenticated],
  )

  const authenticate = useCallback(
    async (credentials: API.LoginRequest<'customer_password'>) => {
      return await fetch(AccountResource.token, {
        ...credentials,
        companyId: company.id,
        grantType: 'customer_password',
      })
        .then(handleAuth)
        .catch((error) => {
          notifyOnError(error)
          setUser(undefined)
          setAuthenticated(false)
          return false
        })
    },
    [company.id, fetch, handleAuth, notifyOnError],
  )

  const ssoLogin = useCallback(
    async (token: string) => {
      return await fetch(AccountResource.sso, {
        accessToken: token,
        grantType: 'sso_token',
        companyId: company.id,
      })
        .then(handleAuth)
        .catch((error) => {
          clearUser()
          throw error
        })
    },
    [clearUser, company.id, fetch, handleAuth],
  )

  useEffect(() => {
    init()

    const id = subscribe({
      event: 'TOKEN',
      callback: (token: TokenEntity) => {
        token && handleAuth(token)
      },
    })

    const logoutId = subscribe({
      event: 'LOGOUT',
      callback: () => {
        logout()
      },
    })

    return () => {
      unsubscribe(id)
      unsubscribe(logoutId)
    }
  }, [])

  if (loggingOut) {
    return <OverlayLoader fullscreen loading={loggingOut} />
  }

  return (
    <Auth.Provider
      value={{
        authenticate,
        ssoLogin,
        authenticated,
        user,
        logout,
        flag: user?.flag ?? '',
        sessionId: sessionId('customer') ?? '',
      }}
    >
      <OverlayLoader loading={loggingOut}>{children}</OverlayLoader>
    </Auth.Provider>
  )
}

const SessionProvider = AuthProvider

const useAuth: () => ContextProps = () => useContext(Auth)

export { Auth, AuthProvider, useAuth }

export default SessionProvider
