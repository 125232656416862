import { FC, KeyboardEvent, useMemo, useState } from 'react'

import { Flex, FormItemProps, FormRule, Input, Progress } from 'antd'
import { createStyles } from 'antd-style'

import { Item } from '.'
import { useI18n } from '../../providers'
import { Descriptions } from '../descriptions'
import { Text } from '../text'
import PhoneNumber from './phone/PhoneNumber'

function preventTab(evt: KeyboardEvent) {
  if (evt.code === 'Tab') evt.preventDefault()
}

export type FormProps<T = any> = {
  errorMessage?: string
  enableTab?: boolean
  disabled?: boolean
  placeholder?: string
  value?: T
  initialValue?: T
  country?: string
  noLabel?: boolean
  onValidate?: (valid: boolean) => void
} & FormItemProps

const useStyles = createStyles(({ css, token }: { css: any; token: any }) => ({
  input: css`
    .pv-input {
      background: ${token.inputBg} !important;
    }  
    .pv-input-number-input {
      background: ${token.inputBg} !important;
    }
  `,
}))

const Email: FC<FormProps> = ({
  placeholder = 'Email Address',
  errorMessage,
  enableTab = true,
  label = 'Email Address',
  name = 'email',
  required,
  disabled,
  onValidate,
  ...props
}) => {
  const { styles } = useStyles()
  return (
    <Item
      className={styles.input}
      data-cy={'Email'}
      label={label}
      name={name}
      rules={[
        {
          required: required,
          message: 'Please enter a valid email address',
        },
      ]}
      {...props}
    >
      <Input
        inputMode={'email'}
        disabled={disabled}
        onKeyDown={(evt) => !enableTab && preventTab(evt)}
        type={'email'}
      />
    </Item>
  )
}

const passwordValidator = (password: string) => {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
  return {
    minChars: password.length >= 8,
    upper: password.toLowerCase() !== password,
    lower: password.toUpperCase() !== password,
    numberOrSymbol: /\d/.test(password) || specialChars.test(password),
  }
}

const PasswordStrengthIndicator: FC<{ input: string }> = ({ input }) => {
  const descriptions = {
    minChars: 'At least 8 characters long',
    upper: '1 uppercase letter',
    lower: '1 lowercase letter',
    numberOrSymbol: '1 number or special character',
  }

  const validation = useMemo(() => passwordValidator(input), [input])

  return (
    <Flex gap={2} vertical>
      <Progress
        percent={Object.values(validation).filter((v) => !!v).length * 25}
        showInfo={false}
        strokeColor={Object.values(validation).filter((v) => !!v).length >= 4 ? '#52c41a' : '#faad14'}
      />
      <Descriptions
        data={Object.keys(validation).map((v) => {
          return {
            text: (
              <Text icon={`symbol/${!!validation[v] ? 'check' : 'close'}`} type={validation[v] ? 'success' : 'danger'}>
                {descriptions[v]}
              </Text>
            ),
          }
        })}
      />
    </Flex>
  )
}

const Password: FC<FormProps & { showLevel?: boolean; onValidate?: (valid: boolean) => void }> = ({
  showLevel = false,
  label = 'Password',
  placeholder = 'Enter your password',
  disabled,
  onValidate,
  ...props
}) => {
  const [value, setValue] = useState('')
  const { t } = useI18n()
  const {styles} = useStyles()
  return (
    <Item
      className={styles.input}
      preserve={false}
      name={props.name ? props.name : 'password'}
      label={label}
      required
      help={showLevel && <PasswordStrengthIndicator input={value} />}
      rules={[
        {
          pattern: /([[:alnum]])([[:alnum]])([[:alnum:]])([[:alnum]]{5,})/,
          validator: (rule: FormRule, password: string, source) => {
            return new Promise<string | undefined>((resolve, reject) => {
              const validate = passwordValidator(password)
              if (Object.values(validate).filter((v) => v).length >= 4) {
                onValidate && onValidate(true)
                resolve(password)
              } else {
                onValidate && onValidate(false)
                reject()
              }
            })
          },
        },
      ]}
    >
      <Input.Password onChange={(evt) => setValue(evt.target.value)} disabled={disabled} />
    </Item>
  )
}

const FormItem = {
  Password,
  Email,
  Phone: PhoneNumber,
} as const

export { FormItem }
